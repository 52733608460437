import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import styles from './footer-logo.module.scss'

export default () => {
  const data = useStaticQuery(
    graphql`
      {
        placeholderImage: file(relativePath: { eq: "eagle-logo-white.png" }) {
          childImageSharp {
            fixed(width: 162, height: 68) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  return (
    <a className={styles.footerLogo} href="http://acaciapharma.com">
      <Img alt="Acacia Pharma logo" fixed={data.placeholderImage.childImageSharp.fixed} />
    </a>
  )
}
