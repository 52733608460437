import React from 'react';
import Container from '~components/container/container.component';
import RenderMarkdown from '~components/RenderMarkdown';

import styles from './page-headline.module.scss';

const PageHeadline = ({ title }) => {
  return (
    <Container>
      <div className={styles.title}>
        <RenderMarkdown 
          text={title}
          paragraph="h1" 
        />
      </div>
    </Container>
  )
};

export default PageHeadline;
