import React, { Component } from 'react';
import Modal from '~components/modal/modal.component';
import styles from './confirmation.module.scss';

const sessionName = 'isHealthCareProConfirmed';

class Confirmation extends Component {
  state = {
    showModal: false,
  }

  cancelUrl = 'https://acaciapharma.com/';

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.setState({
        showModal: !sessionStorage.getItem(sessionName) ? true : false,
      });
    }
  }

  handleCancel = () => {
    window.location.href = this.cancelUrl;
  }

  handleConfirm = () => {
    this.setState({ showModal: false });
    sessionStorage.setItem(sessionName, true);
  }

  render() {
    const { handleCancel, handleConfirm } = this;
    const { showModal } = this.state;
    return (
      <Modal
        showModal={showModal}
        overrideProps={{
          shouldCloseOnOverlayClick: true,
          className: styles.content,
        }}
      >
        <div className={styles.close}>
          <button
            onClick={handleCancel}
          >
            <span className={styles.srText}>Close Modal</span>
            <i className="fas fa-times" />
          </button>
        </div>
        <h1 className={styles.header}>For Healthcare Professionals</h1>
        <p>The information contained in this website is intended for U.S. healthcare professionals only. Please certify that you are a U.S. healthcare professional by selecting "Yes" or "No" below.</p>
        <div className={styles.btnsWrapper}>
          <button
            className={styles.btn}
            onClick={handleConfirm}
          >
            Yes
          </button>
          <button
            className={styles.btn}
            onClick={handleCancel}
          >
            No
          </button>
        </div>
      </Modal>
    );
  }
}

export default Confirmation;
