import React from 'react';
import { Link } from 'gatsby';

import styles from './buttons.module.scss';

export const BtnPrimary = ({ className = '', path, children }) => {
  if (path && path.startsWith('http')) {
    return (
      <a
        className={[styles.btnPrimary, className].join(' ').trim()}
        href={path}
        title={children}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        {children}
      </a>
    );
  }
  else {
    return (
      <Link
        className={[styles.btnPrimary, className].join(' ').trim()}
        to={`/${path}/`}
      >
        {children}
      </Link>
    );
  }
};

export const BtnSecondary = ({ className = '', path, children }) => {
  if (path && path.startsWith('http')) {
    return (
      <a
        className={[styles.btnSecondary, className].join(' ').trim()}
        href={path}
        title={children}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        {children}
      </a>
    );
  }
  else {
    return (
      <Link
        className={[styles.btnSecondary, className].join(' ').trim()}
        to={`/${path}/`}
      >
        {children}
      </Link>
    );
  }
};
