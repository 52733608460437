import React, { useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Container from '~components/container/container.component';
import RenderMarkdown from '~components/RenderMarkdown';

import styles from './isi.module.scss';

const Isi = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulIsiComponent {
        body {
          childMarkdownRemark {
            html
          }
        }
        ribbon {
          ribbon
        }
        heading {
          heading
        }
        boxedWarning {
          boxedWarning
        }
      }
    }
  `);

  const isiStatic = useRef(null);
  const isiFixed = useRef(null);
  const btnExpand = useRef(null);
  const btnCollapse = useRef(null);

  let isIsiClosed = false;
  const [hideIsi, setHideIsi] = useState(isIsiClosed);

  useEffect(() => {
    if (!isiFixed.current || hideIsi) {
      return;
    }
    setHideIsi(isIsiClosed);

    if (isiStatic.current.getBoundingClientRect().top < window.innerHeight * 0.83) {
      setHideIsi(true);
    } else {
      // Throttling scroll event
      // https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event#Examples
      let isiPosition = 0;
      let ticking = false;

      window.addEventListener('scroll', () => {
        if (isiFixed.current) {
          isiPosition = isiStatic.current.getBoundingClientRect().top;

          if (!ticking) {
            window.requestAnimationFrame(function() {
              if (isiPosition < window.innerHeight * 0.83) {
                isiFixed.current.style.display = 'none';
              } else {
                isiFixed.current.style.display = 'block';
              }
              ticking = false;
            });

            ticking = true;
          }
        }
      });
    }
  }, [isIsiClosed, hideIsi]);

  const handleExpandButton = () => {
    isiFixed.current.classList.add('active');
  };

  const handleCollapseButton = () => {
    isiFixed.current.classList.remove('active');
  };

  return (
    <>
      <div id="isi" ref={isiStatic}>
        <div className={styles.isiRibbon}>
          <div className={styles.isiRibbonWrapper}>
            <RenderMarkdown text={data.contentfulIsiComponent.ribbon.ribbon} />
          </div>
        </div>
        <div className={styles.isiHeading}>
          <RenderMarkdown text={data.contentfulIsiComponent.heading.heading} />
        </div>
        <div className={styles.isiBox}>
          <RenderMarkdown text={data.contentfulIsiComponent.boxedWarning.boxedWarning} />
        </div>
        <div
          className={[styles.isi, styles.isiStatic].join(' ')}
          dangerouslySetInnerHTML={{
            __html: data.contentfulIsiComponent.body.childMarkdownRemark.html,
          }}
        />
      </div>
      {!hideIsi &&
        <div ref={isiFixed} className={styles.isiFixed}>
          <div className={styles.isiRibbon}>
            <Container className={styles.container}>
              <div className={styles.isiRibbonWrapper}>
                <RenderMarkdown text={data.contentfulIsiComponent.ribbon.ribbon} />
              </div>
              <button
                ref={btnExpand}
                type="button"
                aria-label="Expand Important Safety Information"
                className={styles.btnExpand}
                onClick={handleExpandButton}
              >
                <i className="fas fa-plus"></i>Expand
              </button>
              <button
                ref={btnCollapse}
                type="button"
                aria-label="Collapse Important Safety Information"
                className={styles.btnCollapse}
                onClick={handleCollapseButton}
              >
                <i className="fas fa-minus"></i>Collapse
              </button>
            </Container>
          </div>
          <Container className={styles.container}>
            <div className={styles.isiHeading}>
              <RenderMarkdown text={data.contentfulIsiComponent.heading.heading} />
            </div>
            <div className={styles.isiBox}>
              <RenderMarkdown text={data.contentfulIsiComponent.boxedWarning.boxedWarning} />
            </div>
            <div
              className={styles.isi}
              dangerouslySetInnerHTML={{
                __html: data.contentfulIsiComponent.body.childMarkdownRemark.html,
              }}
            />
          </Container>
        </div>
      }
    </>
  )
};

export default Isi;
