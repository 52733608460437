import React from 'react'
import Container from '../container/container.component'
import FooterCta from '../footer-cta/footer-cta.component'
import FooterMenu from '../footer-menu/footer-menu.component'
import FooterLogo from '../footer-logo/footer-logo.component'

import styles from './footer.module.scss'

const FooterLegalStatement = () => (
  <div className={styles.footerLegalStatement}>
    <div>
      <p>Byfavo<sup>&reg;</sup> is a registered trademark of Acacia Pharma Limited.</p>
      <p>©2024 Acacia Pharma Inc. All rights reserved. MLR-BYF-US-0048 07/2024</p>
      <p>Acacia Pharma Limited and Acacia Pharma Inc. are wholly owned subsidiaries of Eagle Pharmaceuticals, Inc.</p>
    </div>
  </div>
)

const Footer = () => (
  <footer className={styles.footer}>
    <Container>
      <FooterCta />
      <div className={styles.footerNav}>
        <FooterMenu />
      </div>
      <div className={styles.footerBottom}>
        <FooterLegalStatement />
        <FooterLogo />
      </div>
    </Container>
  </footer>
);

export default Footer;