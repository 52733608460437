import React, { Component } from 'react';
import Modal from '~components/modal/modal.component';

import styles from './schedule.module.scss';

class ScheduleConversation extends Component {
  constructor () {
    super();
    this.state = {
      showModal: false
    };

    this.schedulePath = 'request-conversation';
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal(e) {
    this.renderForm();
    e.preventDefault();
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  createScript(data, type) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    type === "src" ? script.src = data : script.innerHTML = data;
    document.body.appendChild(script);
  }

  renderForm() {
    const settings = `var ss_form = {'account': 'MzawMLEwMDUxAgA', 'formID': 'S0w1SEy2ME3VNTQxS9Q1MU4y0000TTbRTUkyNzCzTLY0MDOzAAA'};
      ss_form.width = '100%';
      ss_form.domain = 'app-3QNLPQABDS.marketingautomation.services';
      ss_form.hidden = {'_usePlaceholders': true};
      ss_form.target_id = 'sharpspring-form-modal';
      ss_form.polling = true;`;
    this.createScript(settings, "script");
    this.createScript("https://koi-3QNLPQABDS.marketingautomation.services/client/form.js?ver=2.0.1", "src");
  }

  componentDidMount() {
    const scheduleLinks = Array.from(document.querySelectorAll(`a[href*="/${this.schedulePath}"]`));
    scheduleLinks.forEach(link => {
      link.addEventListener('click', this.handleOpenModal);
    });
  }

  render() {
    return (
      <Modal
        showModal={this.state.showModal}
        overrideProps={{
          className: styles.dialog,
          onRequestClose: this.handleCloseModal,
        }}
      >
        <div className={styles.close}>
          <button
            aria-label="Close Request a conversation modal"
            onClick={this.handleCloseModal}
          >
            <i className="fas fa-times" />
          </button>
        </div>
        <h1 className={styles.title}>Request a conversation</h1>
        <div id="sharpspring-form-modal" />
      </Modal>
    );
  }
}

export default ScheduleConversation;
